/*
 * Video.js ~ Code that initializes and sets up the Video Player.
 */

export default class Video {
    constructor(target) {
        this.video = target;
        this.onTimeUpdateMethods = [];
        this.overlays = [];
        this.lastTimepoint = -1;
        this.volume = 100;
        this.gotoLastChapterAfterCurrent = false;
        this.video.addEventListener('timeupdate', (e) => {
            this.onTimeUpdateMethods.forEach((method) => {
                method(this.video.currentTime, this.video.duration, e.target, this);
            });
        });

        this.video.addEventListener('loadeddata', () => {
            this.video.style.visibility = 'visible';
        });
    }

    goto(time) {
        this.video.currentTime = parseFloat(time);
    }

    // Video controls -----------------

    play() {
        this.video.play();
    }

    pause() {
        this.video.pause();
    }

    paused() {
        return this.video.paused;
    }

    onTimeUpdate(method) {
        this.onTimeUpdateMethods.push(method);
    }

    // -------------------------------

    updateOverlay(time) {
        const overlayKeys = Object.keys(this.overlays);

        overlayKeys.forEach((key) => {
            const layer = this.overlays[key];
            const {start, end} = layer.dataset;

            // Every time the player "Enters" the overlay, display overlay.
            if (start <= time && end >= time && end >= this.lastTimepoint) {
                layer.style.display = 'inline-block';
                this.lastTimepoint = time;
            }
            else {
                layer.style.display = 'none';
            }
            this.lastTimepoint = time;
        });
    }

    // Overlay -----------------------

    attachOverlay(overlay) {
        this.overlays.push(overlay);

        this.onTimeUpdate(this.updateOverlay);
        this.updateOverlay(0, null, null, this);

        overlay.querySelectorAll('div').forEach((element) => {
            if (element.dataset.onclickgoto !== undefined
                || element.dataset.gotochapter !== undefined) {
                element.addEventListener('click', () => {
                    if (element.dataset.onclickgoto !== undefined) {
                        this.goto(element.dataset.onclickgoto);
                        this.video.play();
                    }

                    if (element.dataset.gotochapter !== undefined) {
                        const chapters = [];

                        document.querySelectorAll('.widget .js--chapters li').forEach((listElement) => {
                            chapters.push(listElement.firstElementChild);
                        });

                        this.goto(chapters[parseInt(element.dataset.gotochapter, 10)].dataset.time);
                        console.log('goto chapter', parseInt(element.dataset.gotochapter, 10), chapters[parseInt(element.dataset.gotochapter, 10)].dataset.time);
                        this.video.play();
                    }
                });
            }
        });
    }

    // -------------------------------
}
