const FEEDBACK_URL = `${HOST_URL}/endpoint/feedback/`;

export default class Overlay {
    constructor(target) {
    // Event listener on Positive feedback
        window.addEventListener('Visualleaflet|Feedback|Positive', () => {
            const request = new XMLHttpRequest();
            request.open('POST', FEEDBACK_URL);
            request.addEventListener('load', () => {
                document.querySelector(`${target} .feedback-overlay`).dataset.start = -1;
                document.querySelector(`${target} .feedback-overlay`).dataset.end = -1;
                document.querySelector(`${target} .feedback-overlay`).dataset.pauze = 'false';
                document.querySelector(`${target} .player`).play();
            });

            const form = new FormData();
            form.append('feedback', 'P');
            form.append('video', document.querySelector(`${target} .widget`).dataset.video);
            request.send(form);
        });

        // Event listener on Negative feedback
        window.addEventListener('Visualleaflet|Feedback|Negative', () => {
            const request = new XMLHttpRequest();
            request.open('POST', FEEDBACK_URL);
            request.addEventListener('load', () => {
                document.querySelector(`${target} .feedback-overlay`).dataset.start = -1;
                document.querySelector(`${target} .feedback-overlay`).dataset.end = -1;
                document.querySelector(`${target} .feedback-overlay`).dataset.pauze = 'false';
                document.querySelector(`${target} .player`).play();
            });

            const form = new FormData();
            form.append('feedback', 'N');
            form.append('video', document.querySelector(`${target} .widget`).dataset.video);
            request.send(form);
        });
    }
}
