/*
 * e-learner.js ~ Code that initializes and sets up the e-learning environment.
 */

import ELearnerQuestion from './e-learner-question';

const ButtonStatus = Object.freeze({send: 0, next: 1, viewScore: 2});

/**
 * An E learner.
 */
export default class ELearner {
    /**
     * Initializes an E learner.
     * @param {any} target The target element.
     * @param {string} apiVideoKey The API video key.
     */
    constructor(target, apiVideoKey) {
        if (target === null || apiVideoKey === 0) {
            return;
        }

        this.container = target;
        this.questions = [];
        this.currentQuestion = null;
        this.apiVideoKey = apiVideoKey;
        this.amountOfCorrectAnswers = 0;
        this.buttonStatus = ButtonStatus.send;
        this.nextButton = this.container.querySelector('.next-button');
        this.finished = false;

        this.amountOfQuestions = this.questions.length;

        this.initialize();
    }

    /**
     * Initializes the E Learner.
     */
    initialize() {
        this.toggleLoadingScreen(true);

        this.getQuestions().then(() => {
            this.showQuestion(this.questions[0]);
        });

        this.nextButton.addEventListener('click', () => {
            switch (this.buttonStatus) {
            case ButtonStatus.send:
                if (this.currentQuestion.isAnswerCorrrect()) {
                    this.amountOfCorrectAnswers += 1;
                }

                this.currentQuestion.showFeedback();

                if (this.finished) {
                    this.nextButton.value = 'Bekijk score';
                    this.buttonStatus = ButtonStatus.viewScore;
                }
                else {
                    this.nextButton.value = 'Volgende';
                    this.buttonStatus = ButtonStatus.next;
                }

                break;

            case ButtonStatus.next:
                this.goToNextQuestion();

                this.nextButton.value = 'Verstuur';
                this.buttonStatus = ButtonStatus.send;

                break;

            case ButtonStatus.viewScore:
                this.finish();

                break;

            default:
            }
        });

        if (this.container.contains(this.container.querySelector('.elearn-finished'))) {
            this.container.querySelector('.elearn-finished').remove();
        }
    }

    /**
     * Toggles the loading screen.
     * @param {Boolean} toggle Boolean value indicating,
     * if the loading screen should be shown or not.
     */
    static toggleLoadingScreen(toggle) {
        const loaderDiv = document.getElementById('elearn-pre');
        loaderDiv.style.display = toggle ? 'block' : 'none';
    }

    static fetchQuestionSet(id) {
        const baseUrl = window.location.href;
        return fetch(`${baseUrl}api/videoquestionset/?video=${id}`, {method: 'GET'})
            .then((res) => res.json().then((data) => data).catch(() => { }));
    }

    /**
     * Gets the questions.
     */
    getQuestions() {
        return this.fetchQuestionSet(this.apiVideoKey).then(((data) => {
            for (let i = 0; i < this.amountOfQuestions; i++) {
                const question = data[0].question[i];

                this.questions.push(
                    new ELearnerQuestion(
                        this.container,
                        this.nextButton,
                        question,
                        i + 1,
                    ),
                );
            }

            this.toggleLoadingScreen(false);
        }));
    }

    /**
     * Shows the given E learner question.
     * @param {ELearnerQuestion} question The E learner question to show.
     */
    showQuestion(question) {
        this.currentQuestion = question;

        question.show();

        this.container.querySelector('.elearn-question-count').innerText = `Vraag ${this.currentQuestion.number} van ${this.amountOfQuestions}`;

        if (this.currentQuestion.number === this.amountOfQuestions) {
            this.finished = true;
        }
    }

    /**
     * Finished the E learner.
     */
    finish() {
        const elearnMediaSection = this.container.querySelector('.elearn-media-section');
        const elearnBody = this.container.querySelector('.elearn-body');

        const eLearnerFinishedElement = document.createElement('div');
        eLearnerFinishedElement.className = 'elearn-finished';
        elearnBody.insertBefore(eLearnerFinishedElement, elearnMediaSection);

        const finishedText = document.createTextNode(`Je bent klaar! Je hebt ${this.amountOfCorrectAnswers} van de ${this.amountOfQuestions} vragen goed beantwoord.`);

        const finishedElement = document.createElement('p');
        finishedElement.className = 'elearn-finished-text';
        finishedElement.appendChild(finishedText);

        eLearnerFinishedElement.appendChild(finishedElement);

        this.currentQuestion.reset();
    }

    /**
     * Goes to the next question.
     */
    goToNextQuestion() {
        this.currentQuestion.reset();

        this.showQuestion(this.questions[this.currentQuestion.number]);
    }
}
