/**
 * An E learner question.
 */
export default class ELearnerQuestion {
    /**
     * Initializes an E learner question.
     * @param {any} target The target element.
     * @param {any} nextButton The next button.
     * @param {any} question The question.
     * @param {number} number The question number.
     */
    constructor(target, nextButton, question, number) {
        this.container = target;
        this.nextButton = nextButton;
        this.question = question;
        this.number = number;

        this.questionText = question.question_text;
        this.questionMediaUrl = question.question_media_url != null ? `https://${window.location.hostname}${question.question_media_url}` : null;
        this.questionExtension = question.question_media_extension;
        this.questionChoices = question.choices;

        this.amountOfCorrectChoices = this.questionChoices.filter((obj) => obj.is_correct).length;
    }

    /**
     * Shows the question.
     */
    show() {
    // Show the question text.
        this.container.querySelector('.elearn-question-text').innerText = `${this.questionText}`;

        // Show the media.
        this.showMedia();

        // Show the choices.
        this.showChoices();

        // Disable the next button.
        this.nextButton.classList.add('disabled', 'disabled-input');
        this.nextButton.disabled = true;
    }

    /**
     * Shows the media for the question.
     */
    showMedia() {
    // Get the media section.
        const mediaSection = this.container.querySelector('.elearn-media-section');

        // Add a row div.
        const mediaSectionRow = document.createElement('div');
        mediaSectionRow.className = 'row elearn-question-media';

        // Add the row div to the media section.
        mediaSection.appendChild(mediaSectionRow);

        // If the question media url, return.
        if (this.questionMediaUrl === null) {
            return;
        }

        let questionMedia;

        // If it's an image.
        if (this.questionExtension === 'png' || this.questionExtension === 'jpg' || this.questionExtension === 'jpeg') {
            questionMedia = new Image();
            questionMedia.src = this.questionMediaUrl;
            // If it's a video.
        }
        else if (this.questionExtension === 'mp4') {
            questionMedia = document.createElement('video');
            questionMedia.setAttribute('controls', '');

            const questionMediaSource = document.createElement('source');
            questionMediaSource.setAttribute('type', 'video/mp4');
            questionMediaSource.src = this.questionMediaUrl;

            questionMedia.appendChild(questionMediaSource);
            // If it's a sound fragment.
        }
        else if (this.questionExtension === 'mp3') {
            questionMedia = new Audio();
            questionMedia.src = this.questionMediaUrl;
            questionMedia.setAttribute('controls', true);
            questionMedia.setAttribute('preload', 'none');
        }

        // Add the question media to the media section row.
        mediaSectionRow.appendChild(questionMedia);
    }

    /**
     * Shows the choices of the question.
     */
    showChoices() {
    // Loop over every question choice.
        for (let i = 0; i < this.questionChoices.length; i++) {
            const questionChoice = this.questionChoices[i];

            // Get the form.
            const elearnForm = this.container.querySelector('.elearn-form');

            // Create a row div.
            const row = document.createElement('div');
            row.className = 'row choice-row';
            row.addEventListener('click', () => this.onCheckmarkClicked(), false);

            // Add it to the form.
            elearnForm.appendChild(row);

            // Create a row content div.
            const rowContent = document.createElement('div');
            rowContent.className = 'elearn-choice-body';

            // Add the row content to the row.
            row.appendChild(rowContent);

            // Create a choice text label.
            const choiceTextLabel = document.createElement('label');
            choiceTextLabel.className = 'elearn-choice-container';

            // Add the answer text to the choice text label.
            choiceTextLabel.appendChild(document.createTextNode(questionChoice.answer_text));

            // Add the choice text label to the row content.
            rowContent.appendChild(choiceTextLabel);

            // Create a choice input element.
            const choiceInput = document.createElement('input');
            choiceInput.setAttribute('type', this.amountOfCorrectChoices > 1 ? 'checkbox' : 'radio');
            choiceInput.setAttribute('id', `q${i}`);
            choiceInput.name = 'elearn-question';
            choiceInput.value = i;
            choiceInput.className = 'elearn-choice';

            // Create a checkmark span.
            const checkmark = document.createElement('span');
            checkmark.className = 'elearn-checkmark';
            checkmark.className += this.amountOfCorrectChoices > 1 ? '-box' : '';

            // Add the choice input element and the checkmark span to row content div.
            choiceTextLabel.appendChild(choiceInput);
            choiceTextLabel.appendChild(checkmark);
        }
    }

    /**
     * Called when a checkmark is clicked.
     */
    onCheckmarkClicked() {
        this.nextButton.classList.remove('disabled', 'disabled-input');
        this.nextButton.disabled = false;
    }

    /**
     * Checks if the question is answered correctly.
     * @returns {Boolean} True if the question is answered correctly, false otherwise.
     */
    isAnswerCorrect() {
        const radioInputs = this.container.querySelectorAll('.elearn-choice');

        const indexesOfCorrectChoices = this.questionChoices
            .filter((item) => item.is_correct)
            .map((item) => this.questionChoices.indexOf(item));

        const indexesOfChosenChoices = Array.from(radioInputs)
            .filter((item) => item.checked)
            .map((item) => Array.from(radioInputs).indexOf(item));

        // eslint-disable-next-line max-len
        return indexesOfCorrectChoices.every((item) => item === indexesOfChosenChoices[indexesOfCorrectChoices.indexOf(item)]);
    }

    /**
     * Resets the question.
     */
    reset() {
    // Get the elements that will reset.
        const buttonBackgroundElement = this.container.querySelector('.elearn-button-background');
        const choiceFeedbackElement = this.container.querySelector('.elearn-choice-feedback');
        const mediaQuestionElement = this.container.querySelector('.elearn-question-media');

        // Reset the background.
        buttonBackgroundElement.classList.remove('elearn-button-background-correct');
        buttonBackgroundElement.classList.remove('elearn-button-background-wrong');

        // Reset the choice feedback.
        choiceFeedbackElement.innerText = '';
        choiceFeedbackElement.classList.remove('elearn-choice-feedback-correct');
        choiceFeedbackElement.classList.remove('elearn-choice-feedback-wrong');

        // Remove the media.
        mediaQuestionElement.remove();

        // Reset the next button.
        this.nextButton.classList.remove('elearn-button-correct');
        this.nextButton.classList.remove('elearn-button-wrong');

        // Remove all rows.
        this.container.querySelectorAll('.choice-row').forEach((a) => {
            a.remove();
        });

        document.styleSheets[0].deleteRule('.elearn-choice-container .elearn-checkmark::after');
        document.styleSheets[0].addRule('.elearn-choice-container .elearn-checkmark::after', 'background: #719fb2 !important;');

        document.styleSheets[0].deleteRule('.elearn-choice-container input:checked ~ .elearn-checkmark-box');
        document.styleSheets[0].addRule('.elearn-choice-container input:checked ~ .elearn-checkmark-box', 'background: #719fb2 !important;');
    }

    /**
     * Shows the feedback of the question.
     */
    showFeedback() {
    // Get the elements.
        const radioInputs = this.container.querySelectorAll('.elearn-choice');
        const radioInputsBody = this.container.querySelectorAll('.elearn-choice-body');
        const buttonBackground = this.container.querySelector('.elearn-button-background');
        const choiceFeedback = this.container.querySelector('.elearn-choice-feedback');

        // Disable the radio buttons.
        this.disableRadioButtons();

        // If there are multiple correct answers.
        if (this.amountOfCorrectChoices > 1) {
            // Just show a message if the answers are correct or not.
            const feedback = this.container.querySelector('.elearn-choice-feedback');

            if (feedback === null) {
                throw new Error('Could not find .elearn-choice-feedback');
            }

            if (this.isAnswerCorrect()) {
                feedback.innerText = 'De gekozen antwoorden zijn correct!';
            }
            else {
                feedback.innerText = 'De gekozen antwoorden zijn niet correct!';
            }
        }
        // If there is only 1 correct answers.
        else {
            const checkedRadioInput = Array.from(radioInputs).filter((x) => x.checked)[0];

            const radioInputArray = Array.from(radioInputs);
            const indexOfCheckedRadioInput = radioInputArray.indexOf(checkedRadioInput);
            const chosenAnswer = this.questionChoices[indexOfCheckedRadioInput];
            // Show the feedback message for the chosen answer.
            this.container.querySelector('.elearn-choice-feedback').innerText = `${chosenAnswer.feedback}`;
        }

        // The class and color values depend on if the answer is correct or not.
        const feedbackClass = this.isAnswerCorrect() ? 'correct' : 'wrong';
        const feedbackColor = this.isAnswerCorrect() ? '#709a0b' : '#ca3b2c';

        // Add the class to all radio inputs.
        for (let i = 0; i < radioInputs.length; i++) {
            radioInputsBody[i].classList.add(`elearn-choice-body-${feedbackClass}`);
            radioInputsBody[i].classList.add(`elearn-choice-body-${feedbackClass}:hover`);

            radioInputsBody[i].classList.remove('elearn-choice-body:hover');
        }

        // Update the choice feedback and button background elements.
        choiceFeedback.classList.add(`elearn-choice-feedback-${feedbackClass}`);
        buttonBackground.classList.add(`elearn-button-background-${feedbackClass}`);

        // Update the next button.
        this.nextButton.classList.add(`elearn-button-${feedbackClass}`);

        // Show the correct color for the feedback.
        document.styleSheets[0].addRule('.elearn-choice-container .elearn-checkmark::after', `background: ${feedbackColor} !important;`);
    }

    /**
     * Disables the radio buttons.
     */
    disableRadioButtons() {
        const radioInputs = this.container.querySelectorAll('.elearn-choice');
        const radioInputsBody = this.container.querySelectorAll('.elearn-choice-body');
        const radioInputsContainer = this.container.querySelectorAll('.elearn-choice-container');

        // Disable all radio buttons.
        for (let i = 0; i < radioInputs.length; i++) {
            radioInputs[i].disabled = true;

            radioInputsBody[i].classList.add('disabled');
            radioInputsContainer[i].classList.add('disabled-input');
        }
    }
}
