/*
 * Widget.js ~ Each instance represents a Widget.
 * TODO:
 *  - Validate the settings that were provided to the Widget constructor.
 */
import 'formdata-polyfill';
import 'core-js/es/object';
import 'core-js/es/map';

import Video from './video';
import Overlay from './overlays';
import ELearner from './e-learner';

export const WIDGET_URL = `//${HOST_URL}/widget/v1/`;
export const RVG_CHECK_URL = `//${HOST_URL}/widget/v1/check-rvg/`;
// export const AUTHENTICATION_URL = 'https://www.beeldsluiter.nl/endpoint/authenticate';

export default class Widget {
    // Constructs a Widget which is the starting point of the API.
    constructor(target, options = {}) {
    // Holds the ID onto which the widget is rendered.
        this.target = typeof (target) === 'string' ? document.querySelector(target) : target;

        // Mapping with the configuration of the Widget.
        this.map = new FormData();
        Object.keys(options).forEach((key) => {
            this.map.append(key, options[key]);
        });

        // Used to keep track of fullscreen of the player.
        this.isFullscreen = false;

        // Reference to the Video object.
        this.video = null;

        // Reference to the overlays over the Widget.
        this.overlays = [];

        // Reference to the eLearner.
        this.eLearner = null;

        // Reference to the current Chapter playing.
        this.currentChapter = null;

        // To edentify if the widget is a video or leaflet as rvg check
        this.widgetEdentifier = null;

        // Reference for getting the api video key, to use it in the elearner
        this.api_video_key = null;

        // Reference for the questionset retrieved from the api, used by the elearner
        this.questionSet = [];
    }

    // Renders the Video in the target provided.
    render() {
    // Attach the Widget stylesheet to the <head> element .
        const widgetCss = document.createElement('link');
        // widgetCss.href = 'https://www.beeldsluiter.nl/static/assets/dist/widget.bundle.css';
        widgetCss.href = `//${HOST_URL}/static/widget/css/widget.css`;
        widgetCss.rel = 'stylesheet';
        widgetCss.type = 'text/css';
        document.head.appendChild(widgetCss);

        const fontawesomeCss = document.createElement('link');
        fontawesomeCss.href = '//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
        fontawesomeCss.rel = 'stylesheet';
        fontawesomeCss.type = 'text/css';
        document.head.appendChild(fontawesomeCss);

        if (!document.querySelector('#bs_gtm')) {
            const googlyticsscript = document.createElement('script');
            googlyticsscript.id = 'bs_gtm';
            googlyticsscript.src = '//www.googletagmanager.com/gtag/js?id=UA-61752485-1';
            googlyticsscript.type = 'text/javascript';
            document.head.appendChild(googlyticsscript);

            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag(...args) {
                window.dataLayer.push(args);
            };
            window.gtag('js', new Date());
            window.gtag('config', 'UA-61752485-1');
        }

        try {
            // Generate video using the configuration provided.
            this.changeVideo(this.map);
        }
        catch (ex) {
            console.error(`An error has occurred: ${ex}`);
        }

        const self = this;

        window.addEventListener('resize', () => {
            self.widgetCss();
        });

    //    document.querySelector(this.target).addEventListener("resize", function(){
    //      console.log('widget video resize');
    //    });
    }

    static validateSettings() {
        throw new Error('Not implemented.');
    }

    changeVideo(form) {
    // Send a POST request and retrieve the Widget
        const request = new XMLHttpRequest();
        request.open('POST', WIDGET_URL);
        request.addEventListener('load', () => {
            // Render as a template so we can add offline event listeners.
            const template = document.createElement('div');
            template.innerHTML = request.responseText;
            const target = template.firstElementChild;
            // Create a new Video object.
            this.video = new Video(target.querySelector('video'));
            /**
             * Append EventListeners to the DOM
             * */

            const self = this;
            let mouseOverVideoControls = false;
            // ------------------------------------------------------------

            // Video controls ---------------------------------------------
            target.querySelector('.js--play-pause').addEventListener('click', () => {
                if (self.video.video.paused === true) {
                    // Play the video
                    self.video.video.play();
                }
                else {
                    // Pause the video
                    self.video.video.pause();
                }
            }, true);

            target.querySelector('#video').addEventListener('click', (event) => {
                const videoControls = document.getElementById('video-controls');
                const videoCursor = document.getElementById('video');

                if (event.target.classList.contains('player') || event.target.classList.contains('video')
                    || event.target.classList.contains('overlay-container')) {
                    if (self.video.video.paused === true) {
                        // Play the video
                        self.video.video.play();
                        if (self.timeout == null && (document.fullscreenElement != null && document.fullscreenElement.classList.contains('video-container'))) {
                            self.timeout = setTimeout(() => {
                                videoControls.style.height = '0px';
                                videoCursor.style.cursor = 'none';
                                clearTimeout(self.timeout);
                                self.timeout = null;
                            }, 3000);
                        }
                    }
                    else {
                        // Pause the video
                        self.video.video.pause();
                        videoCursor.style.cursor = 'default';
                        videoControls.style.height = 'auto';
                        clearTimeout(self.timeout);
                        self.timeout = null;
                    }
                }
            }, true);

            target.querySelector('#video').addEventListener('mousemove', () => {
                const videoControls = document.getElementById('video-controls');
                const videoCursor = document.getElementById('video');
                videoControls.style.height = 'auto';
                videoCursor.style.cursor = 'default';
                if (self.timeout == null) {
                    self.timeout = setTimeout(() => {
                        if (!mouseOverVideoControls) {
                            videoControls.style.height = '0px';
                            videoCursor.style.cursor = 'none';
                        }
                        clearTimeout(self.timeout);
                        self.timeout = null;
                    }, 2000);
                }
            }, true);

            target.querySelector('#video-controls').addEventListener('mouseover', () => {
                mouseOverVideoControls = true;
            }, true);

            target.querySelector('#video-controls').addEventListener('mouseout', () => {
                mouseOverVideoControls = false;
            }, true);

            self.video.video.addEventListener('play', () => {
                // when the video plays it changes the play button
                target.querySelector('.js--play-pause').style.backgroundImage = `url('//${HOST_URL}/static/widget/image/pause_BS-blue.svg')`;
            }, true);

            self.video.video.addEventListener('pause', () => {
                target.querySelector('.js--play-pause').style.backgroundImage = `url('//${HOST_URL}/static/widget/image/play_BS-blue.svg')`;
            }, true);

            document.addEventListener('fullscreenchange', () => {
                // change fullscreen button
                if (document.fullscreenElement) {
                    target.querySelector('.js--full-screen').style.backgroundImage = `url('//${HOST_URL}/static/widget/image/exit-fullscreen_BS-blue.svg')`;
                }
                else {
                    target.querySelector('.js--full-screen').style.backgroundImage = `url('//${HOST_URL}/static/widget/image/fullscreen_BS-blue.svg')`;
                }
            });

            // makes the video go fullscreen
            target.querySelector('.js--full-screen').addEventListener('click', () => {
                if (document.fullscreenElement) {
                    if (document.cancelFullScreen) {
                        document.cancelFullScreen();
                    }
                    else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    }
                    else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    }
                    else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                }
                else if (self.video.video.requestFullscreen) {
                    self.target.querySelector('.video-container').requestFullscreen();
                }
                else if (self.video.video.mozRequestFullScreen) {
                    self.target.querySelector('.video-container').mozRequestFullScreen(); // Firefox
                }
                else if (self.video.video.webkitRequestFullscreen) {
                    self.target.querySelector('.video-container').webkitRequestFullscreen(); // Chrome and Safari
                }
            }, true);

            // Creating calls for seek-bar
            target.querySelector(`#${this.video.video.id}`).addEventListener('loadeddata', () => {
                // Video is loaded and can be played
                target.querySelector('.js--seek-bar').max = parseInt(self.video.video.duration, 10) / 5;
            }, false);

            target.querySelector('.js--seek-bar').addEventListener('input', (elem) => {
                // Calculate the new time
                const time = elem.target.value * 5;
                // Update the video time
                self.video.video.currentTime = time;
            }, true);

            this.video.onTimeUpdate(() => {
                // Calculate the slider value
                const value = self.video.video.currentTime / 5;
                // Update the slider value
                target.querySelector('.js--seek-bar').value = value;
            }, true);

            target.querySelector('.js--volume-bar').addEventListener('input', () => {
                self.video.video.muted = false;
                target.querySelector('.js--mute').style.backgroundImage = `url('//${HOST_URL}/static/widget/image/volume_BS-blue.svg')`;

                if (target.querySelector('.js--volume-bar').value <= 0) {
                    target.querySelector('.js--mute').style.backgroundImage = `url('//${HOST_URL}/static/widget/image/no-volume_BS-blue.svg')`;
                }

                // Update the video volume
                self.video.video.volume = target.querySelector('.js--volume-bar').value;
            }, true);

            target.querySelector('.js--mute').addEventListener('click', () => {
                if (self.video.video.muted) {
                    // change mute button
                    target.querySelector('.js--mute').style.backgroundImage = `url('//${HOST_URL}/static/widget/image/volume_BS-blue.svg')`;
                }
                else {
                    target.querySelector('.js--mute').style.backgroundImage = `url('//${HOST_URL}/static/widget/image/no-volume_BS-blue.svg')`;
                }

                self.video.video.muted = !self.video.video.muted;
            }, true);

            // subtitles

            // turn all subtitle mode to hidden
            for (let i = 0; i < self.video.video.textTracks.length; i++) {
                self.video.video.textTracks[i].mode = 'hidden';
            }

            // when clicking on the subtitle button it shows the subtitle menu
            target.querySelector('.js--subtitle').addEventListener('click', () => {
                const subtitleMenu = document.getElementById('subtitle_ul');
                if (subtitleMenu.style.display === 'block') {
                    subtitleMenu.style.display = 'none';
                }
                else {
                    subtitleMenu.style.display = 'block';
                }
            }, true);

            target.querySelector('#subtitle_ul').addEventListener('click', () => {
                const subtitleMenu = document.getElementById('subtitle_ul');
                if (subtitleMenu.style.display === 'block') {
                    subtitleMenu.style.display = 'none';
                }
                else {
                    subtitleMenu.style.display = 'block';
                }
            }, true);

            // if there is no subtitle it doesnt show the subtitle button
            const subtitleMenu = target.querySelector('.js--subtitle');
            if (self.video.video.textTracks.length === 0) {
                subtitleMenu.style.display = 'none';
            }

            // when clicking on the off button it turns off the subtitle that is showing
            target.querySelector('.js--button_off').addEventListener('click', () => {
                const tracks = self.video.video.textTracks;
                for (let i = 0; i < tracks.length; i++) {
                    tracks[i].mode = 'hidden';
                }
            }, true);

            // when clicking on a language button it changes the track mode to showing
            function attachListener(button, track) {
                button.addEventListener('click', (event) => {
                    event.preventDefault();
                    const tracks = self.video.video.textTracks;
                    for (let i = 0; i < tracks.length; i++) {
                        tracks[i].mode = 'hidden';
                    }
                    // eslint-disable-next-line no-param-reassign
                    track.mode = 'showing';
                });
            }

            // when a subtitle is added it shows it in the subtitle menu
            const tracks = self.video.video.textTracks;
            for (let i = 0; i < tracks.length; i++) {
                const button = target.querySelector(`.js--button_${tracks[i].language}`);
                attachListener(button, tracks[i]);
            }
            // -----------------------------------------------------------

            // Registration Code overlay
            if (target.dataset.visualleaflet !== '') {
                this.widgetEdentifier = `_WDGT_LEAFLET_${target.dataset.visualleaflet}`;

                // GA add post
                window.gtag('config', 'UA-61752485-1', {
                    page_path: `/leaflet/${target.dataset.visualleaflet}`,
                });
            }
            else {
                this.widgetEdentifier = `_WDGT__VIDEO_${target.dataset.video}`;
            }

            // GA add post
            window.gtag('config', 'UA-61752485-1', {
                page_path: `/video/${target.dataset.video}`,
            });

            //   window.gtag('create', 'UA-61752485-1', 'auto', 'bs_gtm');
            //   window.gtag('bs_gtm.set', 'page', '/video/' +  target.dataset.video);
            //   window.gtag('bs_gtm.send', 'pageview');

            // Attach Registration Code overlay. Capture and Stop event propagation.
            const handle = (event) => {
                if (!event.target.classList.contains('exempt-click-prevention')) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            };

            if (this.map.get('authentication') === 'true') {
                target.querySelector('.widget').addEventListener('click', handle, true);
            }

            // Attach Overlay bindings
            target.querySelectorAll('.overlay > div').forEach((overlay) => {
                this.video.attachOverlay(overlay);
            });

            // Attach the click-play onclick handler
            target.querySelector('video').addEventListener('play', (e) => {
                e.preventDefault();
                if (localStorage.getItem(this.widgetEdentifier) != null || this.target.querySelector('video').attributes.noRVG) {
                    this.video.play();
                }
                else {
                    this.video.pause();
                }
            });

            // Attach the click-play onclick handler
            target.querySelector('video').addEventListener('click', () => {
                if (this.video.video.paused) {
                    if ((localStorage.getItem(this.widgetEdentifier) != null || this.target.querySelector('video').attributes.noRVG)) {
                        this.video.play();
                        return;
                    }
                }
                this.video.pause();
            });

            if (target.querySelector('.widget .js--button--fing-rvg-code') !== null) {
                target.querySelector('.widget .js--button--fing-rvg-code').addEventListener('click', (e) => {
                    e.preventDefault();
                    target.querySelector('.widget .js--rvg-info-text').style.maxHeight = target.querySelector('.widget .js--rvg-info-text').style.maxHeight === '0px' ? '150px' : '0px';
                });
            }

            const mainModalDiv = document.querySelector('.modal');

            // check the RVG code input
            const sendRVG = (videoId, code) => {
                const rvgCode = new XMLHttpRequest();
                const formValues = this.target.querySelector(`.widget #rvg_code_form_${this.video.video.id}`);
                const formData = new FormData(formValues);

                formData.append(`user_rvg_code_${videoId}`, code);

                rvgCode.open('POST', RVG_CHECK_URL);
                rvgCode.addEventListener('load', () => {
                    if (JSON.parse(rvgCode.responseText).success === true) {
                        if (mainModalDiv != null) {
                            mainModalDiv.style.overflowY = 'auto';
                        }
                        this.target.querySelector(`.widget #widget-rvg-${this.video.video.id}`).parentElement.removeChild(this.target.querySelector(`.widget #widget-rvg-${this.video.video.id}`));

                        const nextYear = new Date();
                        nextYear.setYear(nextYear.getYear() + 1);
                        localStorage.setItem(this.widgetEdentifier, true);
                        this.video.play();
                        return true;
                    }
                    const rvgField = this.target.querySelector(`#user_rvg_code_${this.video.video.id}`) !== null
                        ? this.target.querySelector(`#user_rvg_code_${this.video.video.id}`)
                        : this.target.querySelector(`#user_rvg_code_${this.video.video.id}_0`);

                    // if(rvg_code !== null && rvg_code.value.length >= 1){
                    if (code.length >= parseInt(formValues.getAttribute('data-rvg-length'), 10)) {
                        rvgField.parentElement.classList.remove('wrong-rvg');
                        this.target.querySelector(`#rvg_code_form_${this.video.video.id}_wrong_message`).style.display = 'none';
                    }

                    if (code.length >= parseInt(formValues.getAttribute('data-rvg-length'), 10)) {
                        if (this.target.querySelector(`#user_rvg_code_${this.video.video.id}`) !== null) {
                            this.target.querySelector(`#user_rvg_code_${this.video.video.id}`).value = '';
                        }
                        else {
                            target.querySelectorAll('.new-rvg-input').forEach((e) => {
                                e.value = '';
                            });

                            target.querySelector('.new-rvg-input').focus();
                        }

                        console.log('Wrong Rvg/Eu code.');
                        rvgField.parentElement.classList.add('wrong-rvg');
                        this.target.querySelector(`#rvg_code_form_${this.video.video.id}_wrong_message`).style.display = 'block';
                    }
                    return false;
                });
                rvgCode.send(formData);
            };

            if (localStorage.getItem(this.widgetEdentifier) != null || target.querySelector('video').attributes.noRVG) {
                target.querySelector(`.widget #widget-rvg-${this.video.video.id}`).parentElement.removeChild(target.querySelector(`.widget #widget-rvg-${this.video.video.id}`));
            }
            else {
                target.querySelectorAll('.new-rvg-input').forEach((e) => {
                    e.addEventListener('keydown', (event) => {
                        // If the backspace key is pressed, or the value is empty.
                        if (event.which === 8 && event.target.value === '') {
                            event.target.previousElementSibling.focus();
                        }
                    });
                    /* eslint-disable no-param-reassign */
                    e.addEventListener('input', (event) => {
                        const input = event.target.value;

                        /*
                        If the user presses and holds the key,
                        prevent the input field from showing more than one character.
                        */
                        if (input.length > 1 || !Number.isInteger(parseInt(input, 10))) {
                            event.target.value = event.target.value[0] || '';
                        }
                        else if (event.target.nextElementSibling !== null && input !== '') {
                            event.target.nextElementSibling.focus();
                        }
                    });
                    /* eslint-enable no-param-reassign */
                    e.addEventListener('keyup', () => {
                        let code = '';

                        target.querySelectorAll('.new-rvg-input').forEach((event) => {
                            code += event.value;
                        });

                        sendRVG(this.video.video.id, code);
                    });

                    e.addEventListener('focus', () => {
                        if (document.querySelector('.modal') != null) {
                            document.querySelector('.modal').style.overflowY = 'hidden';
                        }
                    });
                });
            }

            function modalScrollController() {
                const inputItems = document.querySelectorAll('.new-rvg-input');
                for (let i = 0; i < inputItems.length; i++) {
                    if (inputItems[i] === document.activeElement) {
                        mainModalDiv.style.overflowY = 'hidden';
                        break;
                    }
                    mainModalDiv.style.overflowY = 'auto';
                }
            }

            if (mainModalDiv != null) {
                mainModalDiv.addEventListener('click', () => {
                    modalScrollController();
                });
                mainModalDiv.addEventListener('keydown', () => {
                    modalScrollController();
                });
            }
            // ------------------------------------------------------------

            // Google tag manager -----------------------------------------
            let videoTenpercent = false;
            let videoTwentypercent = false;
            let videoThirtypercent = false;
            let videoFortypercent = false;
            let videoFiftypercent = false;
            let videoSixtypercent = false;
            let videoSeventypercent = false;
            let videoEightypercent = false;
            let videoNinetypercent = false;
            let videoHunderdpercent = false;

            this.video.onTimeUpdate((time, duration, video) => {
                if (window.bs_dataLayer) {
                    const currentTime = Math.floor((time / duration) * 100);
                    const dataLayerObject = {
                        event: 'widget_video_playing',
                        widget_video_name: `Widget_Video_${video.getAttribute('visualleafletname')}_${video.getAttribute('languagename')}`,
                        widget_video_current_time: `${currentTime} %`,
                    };

                    if (currentTime === 10 && !videoTenpercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoTenpercent = true;
                        videoHunderdpercent = false;
                    }
                    else if (currentTime === 20 && !videoTwentypercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoTwentypercent = true;
                        videoTenpercent = false;
                    }
                    else if (currentTime === 30 && !videoThirtypercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoThirtypercent = true;
                        videoTwentypercent = false;
                    }
                    else if (currentTime === 40 && !videoFortypercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoFortypercent = true;
                        videoThirtypercent = false;
                    }
                    else if (currentTime === 50 && !videoFiftypercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoFiftypercent = true;
                        videoFortypercent = false;
                    }
                    else if (currentTime === 60 && !videoSixtypercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoSixtypercent = true;
                        videoFiftypercent = false;
                    }
                    else if (currentTime === 70 && !videoSeventypercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoSeventypercent = true;
                        videoSixtypercent = false;
                    }
                    else if (currentTime === 80 && !videoEightypercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoEightypercent = true;
                        videoSeventypercent = false;
                        console.log(currentTime);
                    }
                    else if (currentTime === 90 && !videoNinetypercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoNinetypercent = true;
                        videoEightypercent = false;
                    }
                    else if (currentTime === 100 && !videoHunderdpercent) {
                        window.bs_dataLayer.push(dataLayerObject);
                        videoHunderdpercent = true;
                        videoNinetypercent = false;
                    }
                }
            });

            // Chapter, FAQ and Language events ----------------------------

            const modalContent = document.querySelector('.modal-content');
            modalContent.addEventListener('click', (event) => {
                const chapterButton = this.target.querySelector('.widget .js--chapter_btn');
                const languageButton = this.target.querySelector('.widget .js--language_btn');
                const faqButton = this.target.querySelector('.widget .js--faq_btn');

                if (chapterButton.classList.contains('active') && !chapterButton.contains(event.target)) {
                    chapterButton.classList.remove('active');
                    this.target.querySelector('.widget .js--chapters').classList.add('hide');
                    this.video.video.classList.toggle('blur');
                }
                else if (languageButton.classList.contains('active') && !languageButton.contains(event.target)) {
                    languageButton.classList.remove('active');
                    this.target.querySelector('.widget .js--languages').classList.add('hide');
                }
                else if (faqButton.classList.contains('active') && !faqButton.contains(event.target)) {
                    faqButton.classList.remove('active');
                    this.target.querySelector('.widget .js--faqs').classList.add('hide');
                }
                // Add blur on video
                this.video.video.classList.toggle('blur');
            });

            target.querySelector('.js--chapter_btn').addEventListener('click', () => {
                if (this.target.querySelector('.widget .js--chapters').classList.contains('hide')) {
                    this.target.querySelector('.widget .js--chapters').classList.remove('hide');
                    this.target.querySelector('.widget .js--chapter_btn').classList.add('active');

                    if (target.querySelector('.js--faq_btn') != null) {
                        this.target.querySelector('.widget .js--faqs').classList.add('hide');
                        this.target.querySelector('.widget .js--faq_btn').classList.remove('active');
                    }
                    this.video.pause();
                }
                else {
                    this.target.querySelector('.widget .js--chapters').classList.add('hide');
                    this.target.querySelector('.widget .js--chapter_btn').classList.remove('active');
                    this.video.play();
                }

                // turn off other tabs when clicked on chapter
                const languageButton = this.target.querySelector('.js--language_btn');
                if (languageButton) {
                    if (languageButton.classList.contains('active')) {
                        languageButton.classList.toggle('active');
                        this.target.querySelector('.widget .js--languages').classList.toggle('hide');
                    }
                }
                // Add blur on video
                this.video.video.classList.toggle('blur');
            });

            if (target.querySelector('.js--faq_btn') != null) {
                target.querySelector('.js--faq_btn').addEventListener('click', () => {
                    if (this.target.querySelector('.widget .js--faqs').classList.contains('hide')) {
                        this.target.querySelector('.widget .js--faqs').classList.remove('hide');
                        this.target.querySelector('.widget .js--faq_btn').classList.add('active');

                        const chapterButton = this.target.querySelector('.widget .js--chapter_btn');
                        if (chapterButton) {
                            if (chapterButton.classList.contains('active')) {
                                chapterButton.classList.remove('active');
                                this.target.querySelector('.widget .js--chapters').classList.add('hide');
                                this.video.video.classList.toggle('blur');
                            }
                        }

                        const languageButton = this.target.querySelector('.widget .js--language_btn');
                        if (languageButton) {
                            if (languageButton.classList.contains('active')) {
                                languageButton.classList.remove('active');
                                this.target.querySelector('.widget .js--languages').classList.add('hide');
                                this.video.video.classList.toggle('blur');
                            }
                        }
                    }
                    else {
                        this.target.querySelector('.widget .js--faqs').classList.add('hide');
                        this.target.querySelector('.widget .js--faq_btn').classList.remove('active');
                    }
                });
            }

            target.querySelector('.js--language_btn').addEventListener('click', () => {
                const chapterButton = this.target.querySelector('.widget .js--chapter_btn');
                if (chapterButton) {
                    if (chapterButton.classList.contains('active')) {
                        chapterButton.classList.remove('active');
                        this.target.querySelector('.widget .js--chapters').classList.add('hide');
                        this.video.video.classList.toggle('blur');
                    }
                }

                const faqButton = this.target.querySelector('.widget .js--faq_btn');
                if (faqButton) {
                    if (faqButton.classList.contains('active')) {
                        faqButton.classList.remove('active');
                        this.target.querySelector('.widget .js--faqs').classList.add('hide');
                    }
                }

                this.target.querySelector('.widget .js--languages').classList.toggle('hide');
                this.target.querySelector('.widget .js--language_btn').classList.toggle('active');
            });

            // Attach Chapter onclick handlers.
            target.querySelectorAll('.js--chapters li a').forEach((chapter) => {
                chapter.addEventListener('click', (e) => {
                    this.video.goto(e.target.dataset.time);
                    this.video.play();
                    this.target.querySelector('.js--chapter_btn').click();
                    if (window.bs_dataLayer) {
                        window.bs_dataLayer.push({
                            event: 'chapter_button',
                            chapter_name: e.target.getAttribute('chapter-title'),
                            widget_video_name: `Widget_Video_${e.target.getAttribute('visualleafletname')}_${e.target.getAttribute('languagename')}`,
                        });
                    }
                });
            });

            // Attach FAQ onclick handlers.
            target.querySelectorAll('.js--faqs li a').forEach((faq) => {
                faq.addEventListener('click', (e) => {
                    this.video.goto(e.target.dataset.time);
                    this.target.querySelector('.widget .js--faqs').classList.add('hide');
                    this.target.querySelector('.widget .js--faq_btn').classList.remove('active');
                });
            });

            // Attach Language onclick handlers.
            target.querySelectorAll('.js--languages li:not(.active)').forEach((language) => {
                language.addEventListener('click', (e) => {
                    this.map.set('video', e.target.dataset.video);
                    this.map.set('language_change', true);
                    this.map.set('authentication', false);
                    this.changeVideo(this.map);
                    if (e.target.getAttribute('language-name') !== null && window.bs_dataLayer) {
                        window.bs_dataLayer.push({
                            event: 'video_widget_language_selected',
                            video_widget_language_button_name: e.target.getAttribute('language-name'),
                            widget_video_name: `Widget_Video_${e.target.getAttribute('visualleafletname')}_${e.target.getAttribute('languagename')}`,
                        });
                    }
                });
            });

            // Attach Videobutton onclick handlers.
            target.querySelectorAll('.video-recommendation').forEach((button) => {
                button.addEventListener('click', () => {
                    this.map.set('video', button.dataset.video);
                    this.map.set('authentication', false);
                    this.changeVideo(this.map);
                });
            });

            // Attach Eventbutton onclick handlers.
            target.querySelectorAll('.event-button').forEach((button) => {
                button.addEventListener('click', (e) => {
                    target.dispatchEvent(new Event(e.target.dataset.event));
                });
            });

            this.target.innerHTML = '';
            this.target.appendChild(target);

            /**
             * Append EventListeners to the DOM after the DOM is inserted
             * */

            // Dynamically update the chapter menu.
            this.currentChapter = null;
            this.video.onTimeUpdate((time) => {
                let chosen = null;
                let chapterNo = 0;

                const chapters = Array.prototype.filter.call(this.target.querySelectorAll('.js--chapters li a'), (f) => !f.classList.contains('hidden'));

                chapters.forEach((chapter) => {
                    if (chapter.dataset.time <= time) {
                        chosen = chapter;
                        chapterNo += 1;
                    }
                });

                if (chapterNo === chapters.length && chapters[chapterNo - 1].classList.contains('hidden')) {
                    this.video.gotoLastChapterAfterCurrent = true;
                }

                if (this.currentChapter !== chosen && chosen !== null) {
                    if (this.currentChapter != null) {
                        this.currentChapter.classList.remove('active');
                    }
                    /*
                    Check if video should go to the last chapter
                    after the current chapter has ended
                    */
                    if (this.video.gotoLastChapterAfterCurrent) {
                        chosen = chapters[chapters.length - 1];
                        this.video.goto(chosen.dataset.time);
                    }

                    this.currentChapter = chosen;
                    this.currentChapter.classList.add('active');
                }
            });
            // --------------------------------

            // Learning initialization --------

            const learnerButton = this.target.querySelector('.video-learner-button');
            let elem = this.target.querySelector('.video-learning-container');

            function removeElement(elementClass) {
                const elements = document.getElementsByClassName(elementClass);

                while (elements[0]) {
                    elements[0].parentNode.removeChild(elements[0]);
                }
            }

            function resetColors() {
                const buttonBackground = document.querySelector('.elearn-button-background');
                const choiceFeedback = document.querySelector('.elearn-choice-feedback');
                const elearnButton = document.querySelector('.elearn-button');
                document.querySelector('.elearn-choice-feedback').innerText = '';

                choiceFeedback.classList.remove('elearn-choice-feedback-correct');
                buttonBackground.classList.remove('elearn-button-background-correct');
                elearnButton.classList.remove('elearn-button-correct');

                choiceFeedback.classList.remove('elearn-choice-feedback-wrong');
                buttonBackground.classList.remove('elearn-button-background-wrong');

                elearnButton.classList.remove('elearn-button-wrong');

                document.styleSheets[0].deleteRule('.elearn-choice-container .elearn-checkmark::after');
                document.styleSheets[0].addRule('.elearn-choice-container .elearn-checkmark::after', 'background: #719fb2 !important;');

                document.styleSheets[0].deleteRule('.elearn-choice-container input:checked ~ .elearn-checkmark-box');
                document.styleSheets[0].addRule('.elearn-choice-container input:checked ~ .elearn-checkmark-box', 'background: #719fb2 !important;');
            }

            function exitHandler() {
                if (document.webkitIsFullScreen === false) {
                    elem.style.display = 'none';
                    removeElement('choice-row');
                    removeElement('elearn-choice-body');
                    removeElement('elearn-choice-container');
                    removeElement('elearn-choice');
                    removeElement('elearn-checkmark');
                    removeElement('elearn-question-media');

                    resetColors();
                }
                else if (document.mozFullScreen === false) {
                    elem.style.display = 'none';
                    removeElement('choice-row');
                    removeElement('elearn-choice-body');
                    removeElement('elearn-choice-container');
                    removeElement('elearn-choice');
                    removeElement('elearn-checkmark');
                    removeElement('elearn-question-media');

                    resetColors();
                }
                else if (document.msFullscreenElement === false) {
                    elem.style.display = 'none';
                    removeElement('choice-row');
                    removeElement('elearn-choice-body');
                    removeElement('elearn-choice-container');
                    removeElement('elearn-choice');
                    removeElement('elearn-checkmark');
                    removeElement('elearn-question-media');

                    resetColors();
                }
            }

            // Open fullscreen of e-learner overlay on button click
            if (learnerButton != null) {
                if (learnerButton.getAttribute('listener') !== 'true') {
                    learnerButton.addEventListener('click', () => {
                        if (elem != null) {
                            elem.classList.toggle('hidden');
                        }

                        const targetElement = this.target.querySelector('.video-learning-container');

                        this.eLearner = new ELearner(targetElement, this.video.video.id.match(/\d+/)[0], 0);

                        if (elem.requestFullscreen) {
                            this.video.pause();
                            targetElement.style.display = 'block';
                            elem.requestFullscreen();
                        }
                        else if (elem.mozRequestFullScreen) {
                            /* Firefox */
                            this.video.pause();
                            elem.style.display = 'block';
                            elem.mozRequestFullScreen();
                        }
                        else if (elem.webkitRequestFullscreen) {
                            /* Chrome, Safari & Opera */
                            this.video.pause();
                            elem.style.display = 'block';
                            elem.webkitRequestFullscreen();
                        }
                        else if (elem.msRequestFullscreen) {
                            /* IE/Edge */
                            this.video.pause();
                            elem.style.display = 'block';
                            elem = window.top.document.body; // To break out of frame in IE
                            elem.msRequestFullscreen();
                        }
                    });

                    learnerButton.setAttribute('listener', 'true');
                }
            }

            // Check if fullscreen is active, close elearner on fullscreenExit
            if (document.addEventListener) {
                document.addEventListener('fullscreenchange', exitHandler, false);
                document.addEventListener('mozfullscreenchange', exitHandler, false);
                document.addEventListener('MSFullscreenChange', exitHandler, false);
                document.addEventListener('webkitfullscreenchange', exitHandler, false);
            }
            // ---------------------------------

            // Overlay Initialization ----------
            this.overlay = new Overlay(this.target);
            this.widgetCss();
        });
        request.send(form);
    }

    gotoOverlay(sec) {
        if (sec !== -1) {
            this.video.goto(sec);
        }
        this.video.play();
    }

    // function that checks the size of the widget and assigns the appropriate class.
    widgetCss() {
        if (this.target.scrollWidth >= 800) {
            this.target.querySelector('.widget #CFAQLV').setAttribute('class', 'flex direction-row justify-content-center wrap-break');
            this.target.querySelector('.widget').classList.remove('widget--small');
        }
        else {
            this.target.querySelector('.widget #CFAQLV').setAttribute('class', 'flex direction-row justify-content-center wrap-break-wrap');
            this.target.querySelector('.widget').classList.add('widget--small');
        }
    }

    static getFullscreenProps() {
        const keys = 'fullscreenElement mozFullScreenElement msFullscreenElement webkitFullscreenElement'.split(' ');
        const prefixes = ' moz ms webkit'.split(' ');
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] in document) {
                return {
                    fullscreenElement: keys[i],
                    fullscreenchange: `${prefixes[i]}fullscreenchange`,
                    exitFullScreen: prefixes[i] + (prefixes[i] === 'moz' ? 'CancelFullScreen' : 'ExitFullscreen'),
                };
            }
        }
        return {
            fullscreenElement: keys[0],
            fullscreenchange: 'fullscreenchange',
            exitFullScreen: 'ExitFullscreen',
        };
    }
}

// ---------------------------------------

// For each polyfill, internet explorer 11
if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        const self = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(self, this[i], i, this);
        }
    };
}

window.Widget = Widget;
